<template>
    <div class="qingwu">
        <div class="admin_main_block">
            <div class="admin_breadcrumb">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/Admin/index' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>提现管理</el-breadcrumb-item>
                    <el-breadcrumb-item>提现风控</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <div class="admin_main_block admin_m15">
            <div class="header-form">
                <div class="admin_main_block_left">
                    <el-form>
                        <el-form-item label="状态">
                            <el-select v-model="where.status" placeholder="请选择" clearable>
                                <el-option label="全部" value=""></el-option>
                                <el-option label="禁用" value="0"></el-option>
                                <el-option label="启用" value="1"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="手机号">
                            <el-input
                                    v-model="where.accountNo"
                                    placeholder="银行卡号"
                                    clearable
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="日期">
                            <el-date-picker
                                    v-model="date"
                                    @change="orderDate" @input="immediUpdate"
                                    type="daterange"
                                    range-separator="~"
                                    value-format="yyyy-MM-dd"
                                    format="yyyy-MM-dd"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" plain icon="el-icon-search" @click="listData()"
                            >条件筛选</el-button
                            >
                        </el-form-item>
                    </el-form>
                </div>

                <div class="admin_main_block_right">
                    <el-row :gutter="20">
                        <el-col :span="12">
                            <el-button type="primary" @click="add('')" icon="el-icon-plus">新增</el-button>
                        </el-col>
                        <el-col :span="12">
                            <el-button type="primary" @click="exportData" disabled icon="el-icon-tickets" >导出数据</el-button >
                        </el-col>
                    </el-row>
                </div>
            </div>
            <div class="admin_table_main">
                <el-table
                        :data="list"
                        v-loading="loading"
                        element-loading-text="拼命加载中"
                        element-loading-spinner="el-icon-loading"
                        element-loading-background="rgba(0, 0, 0, 0.1)"
                        @selection-change="handleSelectionChange"
                >
                    <el-table-column prop="id" label="ID"></el-table-column>
                    <el-table-column prop="userId" label="用户ID"></el-table-column>
                    <el-table-column prop="accountNo" label="用户账号"></el-table-column>
                    <el-table-column prop="bankAccountNo" label="银行卡号"></el-table-column>
                    <el-table-column prop="dayCountLimit" label="日笔数限制"></el-table-column>
                    <el-table-column prop="daySumLimit" label="日汇总限制"></el-table-column>
                    <el-table-column prop="singleMaxLimit" label="单笔限额"></el-table-column>
                    <el-table-column prop="status" label="状态">
                        <template slot-scope="scope">
                            <el-tag type="warning" v-if="scope.row.status === '0'">禁用</el-tag>
                            <el-tag type="success" v-else-if="scope.row.status === '1'">启用</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="operator" label="操作人"></el-table-column>
                    <el-table-column prop="createTime" label="日期"></el-table-column>
                    <el-table-column label="操作" fixed="right" width="180px">
                        <template slot-scope="scope">
                            <el-button type="warning" class="el-icon-delete" @click="deleteBtn(scope.row.id)" v-if="scope.row.status === '1'">禁用</el-button>
                            <el-button type="success" class="el-icon-check" @click="deleteBtn(scope.row.id)" v-else-if="scope.row.status === '0'">启用</el-button>
                            <el-button type="primary" class="el-icon-edit" @click="add(scope.row.id)">编辑</el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <div class="admin_table_main_pagination">
                    <el-pagination
                            @current-change="currentChange"
                            @size-change="handleSizeChange"
                            background
                            :total="totalData"
                            :page-size="pageSize"
                            :current-page="currentPage"
                            :page-sizes="[10, 20, 50, 100]"
                            layout="sizes, prev, pager, next, total, jumper"
                    ></el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        components: {
        },
        props: {
            state: Number,
        },
        data() {
            return {
                list: [],
                date: "",
                row: {},
                loading: false,
                dialogDetailVisible: false,
                dialogEditVisible: false,
                totalData: 0, // 总条数
                pageSize: 20,
                currentPage: 1,
                rolesData: [],
                selectId: "",
                where: {
                    pageNum: 0,
                    pageSize: 0,
                    accountNo:'',
                    status:null,
                    startDt:'',
                    endDt:'',
                },
            };
        },
        methods: {
            listData() {
                this.loading = true;
                this.where.pageNum = this.currentPage;
                this.where.pageSize = this.pageSize;
                this.$post(this.$api.profitCashLimit, this.where).then((res) => {
                    this.totalData = res.data.total;
                    this.list = res.data.records;
                    this.loading = false;
                });
            },
            handleSelectionChange(e) {
                let ids = [];
                e.forEach((v) => {
                    ids.push(v.id);
                });
                this.selectId = ids.join(",");
            },
            currentChange(e) {
                this.currentPage = e;
                this.listData();
            },
            handleSizeChange(e) {
                this.pageSize = e;
                this.listData();
            },
            orderDate(obj) {
                if (obj) {
                    this.where.startDt = obj[0];
                    this.where.endDt = obj[1];
                }
            },
            immediUpdate(e) {
                this.$nextTick(() => {
                    console.log(e)
                    if(e==null){
                        this.where.startDt = '';
                        this.where.endDt = '';
                    }
                });
            },
            exportData() {
                // console.log("exportData");
            },
            add(id) {
                this.$router.push({ name: "riskManagementAdd" ,query:{"id":id}});
            },
            deleteBtn(id) {
                this.$confirm("是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        const url = this.$api.profitCashLimitDel+id;
                        this.post(url, this.form);
                    })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "已取消",
                        });
                    });
            },
            post(url, data) {
                let that = this;
                this.$get(url, data).then((res) => {
                    if (res.code == 1) {
                        this.$message({
                            type: "success",
                            message: "成功!",
                        });
                        that.listData();
                    } else {
                        this.$message({
                            type: "error",
                            message: "失败!",
                        });
                    }
                });
            },
        },
        created() {
            this.listData();
        },
    };
</script>
<style lang="scss" scoped>
    .endDate {
        color: #c0c4cc;
    }
    .el-image {
        height: 100px;
        width: 100px;
    }
    .el-select {
        width: 120px;
    }
</style>
